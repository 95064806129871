import { getCurrentInstance, Ref } from "vue";
import { scopesGraph } from "@/authConfig";
import { AccessToken } from "@/plugins/apiHelpers";
import { MsGraphApis } from "@/plugins/graphApiPlugin";
import { useBackendApiBase } from "./useApiBase";

export function useGraphApi(
  internalInstance = getCurrentInstance(),
): MsGraphApis {
  if (!internalInstance) {
    throw new Error("Must only be used inside setup() function of a component");
  }

  useBackendApiBase({
    vueComponentInstance: internalInstance,
    oauthScopes: scopesGraph,
    tokenValidSetter: (b) => {
      const graphApis =
        internalInstance.appContext.config.globalProperties.$msGraphApis;
      graphApis.isTokenCurrentlyValid.value = b;
      graphApis.isTokenInitialized.value =
        b || graphApis.isTokenInitialized.value;
    },
    accessToken:
      internalInstance.appContext.config.globalProperties
        .$msGraphApiAccessToken,
    lockName: "graphApi",
    accessTokenLock:
      internalInstance.appContext.config.globalProperties
        .$msGraphApiAccessTokenLock,
  });

  return internalInstance.appContext.config.globalProperties.$msGraphApis;
}

declare module "vue" {
  interface ComponentCustomProperties {
    $msGraphApiAccessToken: Ref<AccessToken | undefined>;
    $msGraphApiAccessTokenLock: Ref<boolean>;
    $msGraphApis: MsGraphApis;
  }
}
