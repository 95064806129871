<template>
  <div class="home">
    <span v-if="!isAuthenticated"
      >Bitte anmelden, um die Applikation zu verwenden.</span
    >
    <template v-else>
      <div class="card-container">
        <Card class="menu-card" v-if="canEditLuematic || canDownloadLuematic">
          <template #title>Lümatic</template>
          <template #content>
            <Button
              label="Vorerfassen"
              @click="router.push({ name: RouteNames.NOTES })"
              :disabled="!canEditLuematic"
            >
            </Button>

            <Button
              @click="router.push({ name: RouteNames.UPLOAD_LUEMATIC })"
              :disabled="!canEditLuematic"
            >
              Hochladen
            </Button>

            <Button
              @click="router.push({ name: RouteNames.DOWNLOAD_LUEMATIC })"
              :disabled="!canDownloadLuematic"
            >
              Herunterladen
            </Button>
          </template>
        </Card>

        <Card class="card-menu" v-if="isBdsUser">
          <template #title>BDS</template>
          <template #content>
            <Button
              label="Werkstattaufträge"
              class="button-size"
              @click="router.push({ name: RouteNames.ORDERS })"
            >
            </Button>
            <Button
              @click="router.push({ name: RouteNames.DOWNLOAD_BDS })"
              label="BDS Lieferscheine"
            >
            </Button>
          </template>
        </Card>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { useRouter } from "vue-router";
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import { OauthRoles, useOauthRoles } from "@/composition-api/useOauthRoles";
  import { RouteNames } from "@/router/RouteNames";
  import Button from "primevue/button";
  import Card from "primevue/card";
  import {
    WatchStopHandle,
    computed,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    ref,
    watch,
  } from "vue";

  const isAuthenticated = useIsAuthenticated();
  const currentInstance = getCurrentInstance();

  const router = useRouter();
  const roles = ref<OauthRoles>();
  let unwatch: WatchStopHandle;
  onMounted(() => {
    unwatch = watch(
      isAuthenticated,
      (v) => {
        if (v) {
          roles.value = useOauthRoles(currentInstance);
        }
      },
      { immediate: true },
    );
  });

  onUnmounted(() => {
    unwatch?.();
  });

  const canEditLuematic = computed(() => roles.value?.canUserEditLuematic);
  const canDownloadLuematic = computed(
    () => canEditLuematic.value || roles.value?.canUserDownloadLuematic,
  );
  const isBdsUser = computed(() => roles.value?.canUserDownloadBds);
</script>

<style scoped lang="scss">
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4em;
    gap: 1em;
  }

  @media only screen and (max-width: 700px) {
    .home {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-container {
      display: flex;
    }
  }

  .button-size {
    height: 100%;
    white-space: nowrap;
  }

  .card-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
</style>
