import { ComputedRef, computed, getCurrentInstance } from "vue";
import { useOauthPermissionsInfo } from "./useOauthPermissionsInfo";
import { oauthRoles } from "@/authConfig";

export interface OauthRoles {
  canUserEditLuematic: ComputedRef<boolean>;
  canUserDownloadLuematic: ComputedRef<boolean>;
  canUserDownloadBds: ComputedRef<boolean>;
}

export function useOauthRoles(instance = getCurrentInstance()) {
  const oauthPermissions = useOauthPermissionsInfo(instance);

  const canUserEditLuematic = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.LUEMATIC),
  );
  const canUserDownloadLuematic = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.LUEMATIC_DOWNLOAD),
  );

  const canUserDownloadBds = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.BDS),
  );

  return {
    canUserEditLuematic,
    canUserDownloadLuematic,
    canUserDownloadBds,
  };
}
