import { RouteLocationNormalized, Router } from "vue-router";
import { msalInstance, scopesGraph } from "@/authConfig";
import {
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";

export function registerGuard(router: Router) {
  router.beforeEach(
    async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      console.log("Guard::registerGuard: routerBeforeEach");
      if (to.meta.requiresAuth) {
        const request = {
          scopes: scopesGraph,
          redirectStartPage: to.fullPath,
        };
        const shouldProceed = await isAuthenticated(msalInstance, request);
        return shouldProceed || "/failed";
      }

      return true;
    },
  );
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  loginRequest: PopupRequest | RedirectRequest,
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }
      // Using redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
      return instance
        .loginRedirect(loginRequest)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    })
    .catch(() => {
      return false;
    });
}
