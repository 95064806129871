import {
  ComponentInternalInstance,
  computed,
  getCurrentInstance,
  ref,
  Ref,
  toRef,
  watch,
} from "vue";
import { defineStore } from "pinia";
import UserInfo from "../utils/UserInfo";
import { useMsal } from "./useMsal";
import { useGraphApi } from "./useMsGraph";
import { blobToDataUri } from "@rsrg-bbw/libraries-common/dist/blobUtils";

export interface UserProfile {
  currentUserId: Ref<string | undefined>;

  getUserInfo(id: string): Ref<UserInfo | undefined>;

  getUserPhoto(id: string): Ref<string | undefined>;
}

export function useUserProfile(
  internalInstance: ComponentInternalInstance | null = getCurrentInstance(),
): UserProfile {
  if (!internalInstance) {
    throw new Error("Must only be used inside setup() function of a component");
  }

  const apis = useGraphApi(internalInstance);
  const store = userProfileStore();

  async function ensureToken(): Promise<void> {
    return new Promise((accept, reject) =>
      apis.callWhenTokenInitialized(accept),
    );
  }

  function fetchUserInfo(id: string): Ref<UserInfo | undefined> {
    let userInfo = ref<UserInfo>();
    ensureToken().then(() => {
      if (id !== currentUserId.value) {
        apis.users.getUserInfo({ id: id }).then((ui) => (userInfo.value = ui));
      } else {
        apis.me.getCurrentUserInfo().then((ui) => (userInfo.value = ui));
      }
    });

    return userInfo;
  }

  function getUserInfo(id: string): Ref<UserInfo | undefined> {
    if (!store.userInfoMap.has(id)) {
      const userInfo = fetchUserInfo(id);
      watch(
        userInfo,
        (up) => {
          if (up) {
            store.userInfoMap.set(id, up);
          }
        },
        {
          immediate: true,
        },
      );
    }
    return toRef(() => store.userInfoMap.get(id));
  }

  function fetchUserPhoto(id: string): Ref<string | undefined> {
    let userPhoto = ref<string>();
    ensureToken().then(() => {
      if (id !== currentUserId.value) {
        apis.users
          .getUserPhoto({ id: id })
          .then((r) => blobToDataUri(r).then((p) => (userPhoto.value = p)))
          .catch((e) => {
            if (e.response?.status === 404) {
              userPhoto.value = undefined;
            }
          });
      } else {
        apis.me
          .getCurrentUserPhoto()
          .then((r) => blobToDataUri(r).then((p) => (userPhoto.value = p)))
          .catch((e) => {
            if (e.response?.status === 404) {
              userPhoto.value = undefined;
            }
          });
      }
    });

    return userPhoto;
  }

  function getUserPhoto(id: string): Ref<string | undefined> {
    if (!store.userPhotoMap.has(id)) {
      const userPhoto = fetchUserPhoto(id);
      watch(
        userPhoto,
        (up) => {
          if (up) {
            store.userPhotoMap.set(id, up);
          }
        },
        {
          immediate: true,
        },
      );
    }
    return toRef(() => store.userPhotoMap.get(id));
  }

  const { accounts } = useMsal(internalInstance);
  const currentUserId: Ref<string | undefined> = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].localAccountId;
    } else {
      return undefined;
    }
  });

  return {
    currentUserId,
    getUserInfo,
    getUserPhoto,
  };
}

const userProfileStore = defineStore("userProfile", () => {
  const userInfoMap = new Map<string, UserInfo>();
  const userPhotoMap = new Map<string, string>();

  return {
    userInfoMap,
    userPhotoMap,
  };
});
