import {
  BrowserAuthOptions,
  Configuration as MsalConfig,
  LogLevel,
  ProtocolMode,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment, Environment } from "./utils/environment";
import { parseClientIds } from "./utils/oauth/oauthHelper";

const clientIdsFrontend: { [key in Environment]: string } = parseClientIds(
  __CLIENT_IDS_FRONTEND__,
  {
    [Environment.DEVELOPMENT]: "ce54bbb0-4d88-4b75-a82d-0def5173ccc1",
    [Environment.TEST]: "00000000-0000-0000-0000-000000000000",
  },
);

const clientIdsBackend: { [key in Environment]: string } = parseClientIds(
  __CLIENT_IDS_BACKEND__,
  {
    [Environment.DEVELOPMENT]: "02b6a7f2-1255-4808-8d63-f20a9be1b7f7",
    [Environment.TEST]: "00000000-0000-0000-0000-000000000000",
  },
);

// Disables the security tests of MSAL. Handy for local, non-https OIDC server
//UrlString.prototype.validateAsUri = () => {};

//authority: "http://localhost:7071",

const browserAuthOptions: BrowserAuthOptions =
  environment == Environment.TEST
    ? {
        protocolMode: ProtocolMode.OIDC,
        clientId: clientIdsFrontend[environment],
        authority: "https://oidc.tst.apps.bbw.rsrg.com",
        knownAuthorities: ["oidc.tst.apps.bbw.rsrg.com"],
        redirectUri: `${window.location.origin}/`, // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: `${window.location.origin}/`, // Must be registered as a SPA redirectURI on your app registration
      }
    : {
        protocolMode: ProtocolMode.AAD,
        clientId: clientIdsFrontend[environment],
        authority:
          "https://login.microsoftonline.com/ad96ab3f-3d64-4455-8acb-2a4f7badc27f",
        redirectUri: `${window.location.origin}/`, // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
      };

// Config object to be passed to Msal on creation
const msalConfig: MsalConfig = {
  auth: browserAuthOptions,
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export enum oauthRoles {
  LUEMATIC = "Luematic.Write",
  LUEMATIC_DOWNLOAD = "Luematic.Download",
  BDS = "BDS.Download",
}

export const scopesBackend = [
  `api://${clientIdsBackend[environment]}/.default`,
];

export const scopesGraph = ["User.Read", "User.ReadBasic.All"];
